// api.js

import axios from '@/store/interceptor';

import apiUrl from '../../config/api.config'

export async function getRequests() {
    try {
        const response = await axios.get(`${apiUrl}/requests`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}
export async function getDepartments() {
    try {
        const response = await axios.get(`${apiUrl}/departments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching departments:", error);
        throw error;
    }
}
export async function getAllRequestsTableData() {
    try {
        const response = await axios.get(`${apiUrl}/requests/requests-table-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}

export async function getResponsiblePersonData(equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${equipmentId}/responsible-person`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch responsible person data:", error);
        throw error;
    }
}

export async function getRequestsTableDataByUser(personId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${personId}/user-requests-table-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}

export async function getUserById(personId) {
    try {
        const response = await axios.get(`${apiUrl}/users/${personId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}

export async function getEquipmentById(equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${equipmentId}/`);
        return response.data;
    } catch (error) {
        console.error("Error fetching requests:", error);
        throw error;
    }
}


export async function getMicroserviceDepartments() {
    try {
        const response = await axios.get(`${apiUrl}/departments/microservice-departments`);
        return response.data;
    } catch (error) {
        console.error("Error fetching departments:", error);
        throw error;
    }
}

export async function fetchEquipmentTypes() {
    try {
        const response = await axios.get(`${apiUrl}/equipment-types`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipment-types:", error);
        throw error;
    }
}
export async function fetchRequestTypes() {
    try {
        const response = await axios.get(`${apiUrl}/request-types`);
        return response.data;
    } catch (error) {
        console.error("Error fetching equipment-types:", error);
        throw error;
    }
}

export async function getEquipmentModels(userId, selectedEquipment) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${userId}/equipment-model`, {
            params: {
                equipmentTypeId: selectedEquipment
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching getEquipmentModels:", error);
        throw error;
    }
}

export async function getEquipmentRegistrationNum(userId, selectedEquipment, selectedModel) {
    try {
        const response = await axios.get(`${apiUrl}/equipments/${userId}/equipment-registration-number`, {
            params: {
                equipmentTypeId: selectedEquipment,
                model: selectedModel,
            }
        });
        return response.data.map(item => ({
            id: item.id,
            registration_number: item.registration_number,
            responsible_person_id: item.responsible_person_id,
            departmentName: item.departmentName,
            locationInfo: item.locationInfo,
            displayText: `${item.registration_number} (${item.departmentName}) - ${item.locationInfo}`
        }));
    } catch (error) {
        console.error("Error fetching getEquipmentRegistrationNum:", error);
        throw error;
    }
}

export async function saveRequestData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/requests`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving request:", error);
        throw error;
    }
}

export async function saveResponseData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/responses`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving request:", error);
        throw error;
    }
}

export async function updateRequests(requestData) {
    try {
        const response = await axios.patch(`${apiUrl}/requests/${requestData.id}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error updating Requests:", error);
        throw error;
    }
}

export async function deleteRequest(requestId) {
    try {
        const response = await axios.delete(`${apiUrl}/requests/${requestId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting Requests:", error);
        throw error;
    }
}

export async function downloadReport(params) {
    try {
        const response = await axios.get(`${apiUrl}/requests/report`, {
            params: params,
            responseType: 'blob'
        });
        return response;
    } catch (error) {
        console.error("Failed to download report:", error);
        throw error;
    }
}

export async function getRequestCommentsHistory(requestId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${requestId}/comments-history`);
        return response.data;
    } catch (error) {
        console.error('Error fetching comments history:', error);
        throw error;
    }
}

export async function getBulkRequestCommentsHistory(requestIds) {
    try {
        const response = await axios.post(`${apiUrl}/requests/bulk-comments-history`, { requestIds });
        return response.data;
    } catch (error) {
        console.error('Error fetching bulk comments history:', error);
        throw error;
    }
}

import axios from '@/store/interceptor';

import apiUrl from '../../config/api.config'

export async function getAllApprovalData() {
    try {
        const response = await axios.get(`${apiUrl}/responses/response-approval-data`);
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export async function getApprovalDataByType(typeId) {
    try {
        const response = await axios.get(`${apiUrl}/responses/${typeId}/response-approval-data`, {
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export async function getUserPhoneNumber(idnp) {
    try {
        const response = await axios.get(`${apiUrl}/users/contacts/${idnp}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user phone number:", error);
        throw error;
    }
}

export async function getLastExecution(userId, equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/last-execution`, {
            params: {
                userId,
                equipmentId
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching user last execution:", error);
        throw error;
    }
}

export async function getApprovalDataByTypeAndDepartment(typeId, department) {
    try {
        const response = await axios.get(`${apiUrl}/responses/${typeId}/response-approval-user-data`, {
            params: {
                curentDepartment: department,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export async function saveResponseData(postData) {
    try {
        const response = await axios.post(`${apiUrl}/responses/`, postData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error saving request:", error);
        throw error;
    }
}

export async function updateRequests(requestData) {
    try {
        const response = await axios.patch(`${apiUrl}/requests/${requestData.id}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error updating Requests:", error);
        throw error;
    }
}

export async function getMaxApproachNumber(userId, equipmentId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/max-approach-number`, {
            params: {
                userId,
                equipmentId,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching max approach number:", error);
        throw error;
    }
}

export async function getRequestCommentsHistory(requestId) {
    try {
        const response = await axios.get(`${apiUrl}/requests/${requestId}/comments-history`);
        return response.data;
    } catch (error) {
        console.error('Error fetching comments history:', error);
        throw error;
    }
}

export async function getBulkRequestCommentsHistory(requestIds) {
    try {
        const response = await axios.post(`${apiUrl}/requests/bulk-comments-history`, { requestIds });
        return response.data;
    } catch (error) {
        console.error('Error fetching bulk comments history:', error);
        throw error;
    }
}
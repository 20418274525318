import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        user: JSON.parse(localStorage.getItem('user')) || null,
        isLoggedIn: !!localStorage.getItem('token'),
        historyPageState: {
            sortBy: 'id',
            sortDesc: true,
            visibleHeaders: [],
            search: '',
            selectedItem: null,
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            state.isLoggedIn = true;
        },
        SET_USER(state, data) {
            state.user = data;
            localStorage.setItem('user', JSON.stringify(data));
        },
        LOGOUT(state) {
            state.token = '';
            state.user = null;
            state.isLoggedIn = false;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        },
        SET_HISTORY_PAGE_STATE(state, historyPageState) {
            state.historyPageState = historyPageState;
        },
    },
    actions: {
        logout({ commit }) {
            commit('LOGOUT');
        },
        saveHistoryPageState({ commit }, historyPageState) {
            commit('SET_HISTORY_PAGE_STATE', historyPageState);
        },
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        getUser: state => state.user,
        isAdmin: state => {
            return state.user.role === 'admin';
        },
        isUser: state => {
            return state.user.role === 'user';
        },
        isRector: state => {
            return state.user.role === 'rector';
        },
        isUserSef: state => {
            return state.user.role === 'usersef';
        },
        isEngineer: state => {
            return state.user.role === 'inginer';
        },
        isEngineerPC: state => {
            return state.user.role === 'inginer-pc';
        },
        isDtiSef: state => {
            return state.user.role === 'dtisef';
        },

        getHistoryPageState: state => state.historyPageState,
    },
});
<template>
  <div class="equipments">
    <v-subheader class="py-0 d-flex justify-space-between rounded-lg">
      <TitleComponent title="Echipamente" class="hide-on-mobile" />
      <v-spacer></v-spacer>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <CreateEquipment
        ref="createEquipment"
        @updateEquipmentData="fetchEquipmentData"
      />
    </v-subheader>
    <br />

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center pe-2">
            <div>&nbsp; Tabelul Echipamentelor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                >
                  {{ getTableCellValue(item, header, index) }}
                  <div v-if="header.value === 'actions' && (isAdmin || isEngineer || isDtiSef)">
                    <v-icon size="big" class="me-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="big" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EditEquipmentPopup
      ref="editEquipmentPopup"
      :equipment="selectedEquipment"
      :visible="showEditPopup"
      @updateEquipmentData="fetchEquipmentData"
    />
    <DeleteItem
      ref="deleteItem"
      :equipment="selectedEquipment"
      :visible="dialogDelete"
      @updateEquipmentData="fetchEquipmentData"
    />
  </div>
</template>

<script>
import CreateEquipment from "./CreateEquipment.vue";
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import DeleteItem from "./DeleteItem.vue";
import EditEquipmentPopup from "./EditEquipmentPopup.vue";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import { mapGetters } from "vuex";

export default {
  name: "EquipmentsPage",
  components: {
    CreateEquipment,
    DeleteItem,
    EditEquipmentPopup,
    TitleComponent,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      headers: [],
      equipmentData: [],
      visibleHeaders: [],
      selectedEquipment: null,
      dialogDelete: false,
      showEditPopup: false,
      search: "",
      sortBy: "id",
      sortDesc: true,
      isLoading: true,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    async fetchEquipmentData() {
      try {
        // if (this.isLoading === false) return; // Prevent duplicate API calls
        
        if (this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector || this.isEquipmentAdmin) {
          const equipmentResponse = await api.getEquipmentsTableData();
          this.equipmentData = equipmentResponse;
        } else {
          const equipmentResponse = await api.getEquipmentsTableDataByUser(
            this.getUser.userId
          );
          this.equipmentData = equipmentResponse;
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },
    editItem(item) {
      this.selectedEquipment = { ...item };
      this.$refs.editEquipmentPopup.show();
    },
    deleteItem(item) {
      this.selectedEquipment = { ...item };
      this.$refs.deleteItem.show();
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "equipmentType") return item.equipmentType;
      if (header.value === "responsable_person") return item.responsable_person;
      if (header.value === "model") return item.model;
      if (header.value === "registration_number")
        return item.registration_number;
      if (header.value === "room") return item.room;
      if (header.value === "department") return item.departmentId.name;
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      this.visibleHeaders = this.headers.filter((header) => header.visible);
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEquipmentAdmin() {
      return this.$store.getters.getUser.role === "equipment_administrator";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer" || this.$store.getters.getUser.role === "inginer-pc";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isRector() {
      return this.$store.getters.getUser.role === "rector";
    },
    filteredData() {
      const result = helper.filteredData(this.equipmentData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./headers.json");
    this.visibleHeaders = this.headers.filter(
      (header) => header.defaultVisible
    );
    this.headers.forEach((header) => {
      header.visible = header.defaultVisible;
    });
    
    // Register event listeners
    this.$refs.deleteItem.$on("error", this.handleError);
    this.$refs.deleteItem.$on("success", this.handleSuccess);
    this.$refs.editEquipmentPopup.$on("error", this.handleError);
    this.$refs.editEquipmentPopup.$on("success", this.handleSuccess);
    this.$refs.createEquipment.$on("error", this.handleError);
    this.$refs.createEquipment.$on("success", this.handleSuccess);
    
    // Fetch data once
    this.$nextTick(() => {
      this.fetchEquipmentData();
    });
  },
};
</script>

<style scoped>
.equipments .v-data-table::v-deep tbody td {
  font-size: 18px;
}
.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>

<template>
  <div class="requests">
    <v-subheader class="py-0 d-flex justify-space-between rounded-lg">
      <TitleComponent title="Demersuri" class="hide-on-mobile" />
      <v-spacer></v-spacer>
      <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
      <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
      <DialogForm ref="dialogForm" @updateRequestData="fetchRequestData" />
    </v-subheader>
    <br />
    <v-row justify="end" v-if="isEngineer || isAdmin || isContability">
      <v-dialog v-model="showDateRangePicker" persistent width="400">
        <template v-slot:activator="{ props }">
          <v-btn
            x-large
            v-bind="props"
            @click="showDateRangePicker = true"
            style="color: white; background-color: #2d8654"
          >
            Descarcă Raportul
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Selectează Intervalul de Date</span>
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="closeDateRangePicker">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="startMenu"
                    v-model="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedStartDate"
                        label="Data Începerii"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                      @change="saveStartDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedEndDate"
                        label="Data Încheierii"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      @change="saveEndDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white; background-color: #2d8654"
              variant="text"
              @click="downloadReport"
            >
              Descarcă
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <br />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center pe-2">
            <div>&nbsp; Tabelul Demersurilor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="paginatedItems"
            :sort-by="pagination.sortBy"
            :sort-desc="pagination.sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
            :options.sync="pagination"
            :server-items-length="filteredData.length"
            @update:options="handleTableOptionsUpdate"
            :multi-sort="false"
            :must-sort="true"
            sortable
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                  :class="{ 'red-row': item.status === 'Respins' }"
                >
                  <template v-if="header.value === 'comments'">
                    <div v-if="item.id" class="comments-wrapper">
                      <v-timeline dense class="compact-timeline">
                        <v-timeline-item
                          v-for="(comment, index) in item.commentsHistory"
                          :key="index"
                          small
                          :color="getStatusColor(comment.status)"
                          class="compact-item"
                        >
                          <div class="comment-header">
                            <strong class="user-name">{{ comment.user }}</strong>
                            <v-chip x-small :color="getStatusColor(comment.status)" class="ml-1">
                              {{ comment.status }}
                            </v-chip>
                          </div>
                          <div class="comment-body">{{ comment.comment }}</div>
                          <div class="comment-date">{{ formatDate(comment.date) }}</div>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                    <div v-else class="no-comments">-</div>
                  </template>
                  <template v-else>
                    {{ getTableCellValue(item, header, index) }}
                    <div v-if="header.value === 'actions' && (isAdmin || isEngineer || isDtiSef)">
                      <v-icon size="big" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <DeleteItem
      ref="deleteItem"
      :request="selectedRequest"
      :visible="dialogDelete"
      @updateRequestData="fetchRequestData"
    />
  </div>
</template>

<script>
import DialogForm from "./DialogForm.vue";
import DeleteItem from "./DeleteItem.vue";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import * as api from "./api";
import * as helper from "@/helper/helper.js";
import { mapGetters } from "vuex";

export default {
  name: "RequestsPage",
  components: {
    DialogForm,
    DeleteItem,
    TitleComponent,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
    ErrorAlert,
  },
  data() {
    return {
      headers: [],
      requestsData: [],
      visibleHeaders: [],
      dialogDelete: false,
      selectedRequest: null,
      search: "",
      sortBy: "id",
      errorMessage: "",
      successMessage: "",
      sortDesc: true,
      acction: false,
      isLoading: true,
      startDate: '',
      endDate: '',
      formattedStartDate: '',
      formattedEndDate: '',
      startMenu: false,
      endMenu: false,
      showDateRangePicker: false,
      commentsCache: {},
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["id"],
        sortDesc: [true]
      },
      commentLoading: false,
      debugMessage: "",
    };
  },
  watch: {
    startDate(newValue) {
      this.formattedStartDate = newValue ? newValue : '';
    },
    endDate(newValue) {
      this.formattedEndDate = newValue ? newValue : '';
    },
    'pagination.page': function() {
      this.fetchCommentsForVisibleItems();
    },
    pagination: {
      handler(newVal, oldVal) {
        if (newVal.page !== oldVal.page || newVal.itemsPerPage !== oldVal.itemsPerPage) {
          this.fetchCommentsForVisibleItems();
        }
      },
      deep: true
    },
    filteredData: {
      handler() {
        this.$nextTick(() => {
          if (!this.isLoading && this.paginatedItems.length > 0) {
            this.fetchCommentsForVisibleItems();
          }
        });
      }
    }
  },
  methods: {
    async fetchRequestData() {
      try {
        this.isLoading = true;
        let requestsResponse = [];
        
        if (this.isAdmin || this.isDtiSef || this.isEngineer || this.isRector) {
          requestsResponse = await api.getAllRequestsTableData();
        } else if (this.isEngineerPC) {
          const allRequests = await api.getAllRequestsTableData();
          requestsResponse = allRequests.filter(item => item.equipment === "Calculatoare");
        } else {
          requestsResponse = await api.getRequestsTableDataByUser(this.getUser.userId);
        }
        
        this.requestsData = requestsResponse.map(item => ({
          ...item,
          commentsHistory: this.commentsCache[item.id] || []
        }));

        await this.fetchCommentsForVisibleItems();
        
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
        this.handleError("Error loading requests data");
      }
    },
    
    async fetchCommentsForVisibleItems() {
      if (this.commentLoading) return;
      
      try {
        this.commentLoading = true;
        
        const visibleItems = this.paginatedItems;
        
        const requestIdsToFetch = visibleItems
          .filter(item => item.id && !this.commentsCache[item.id])
          .map(item => item.id);
        
        if (requestIdsToFetch.length === 0) {
          this.commentLoading = false;
          return;
        }
        
        console.log(`Fetching comments for ${requestIdsToFetch.length} requests:`, requestIdsToFetch);
        
        const commentsData = await api.getBulkRequestCommentsHistory(requestIdsToFetch);
        
        Object.entries(commentsData).forEach(([requestId, comments]) => {
          const numericId = parseInt(requestId, 10);
          this.commentsCache[numericId] = comments;
          
          const item = this.requestsData.find(item => item.id === numericId);
          if (item) {
            item.commentsHistory = comments;
          }
        });
        
        console.log("Comments loaded successfully");
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        this.commentLoading = false;
      }
    },
    
    handleTableOptionsUpdate(options) {
      console.log("Table options updated:", options);
      
      const sortBy = options.sortBy && options.sortBy.length ? options.sortBy : ["id"];
      const sortDesc = options.sortDesc && options.sortDesc.length ? options.sortDesc : [true];
      
      this.pagination = {
        page: options.page || 1,
        itemsPerPage: options.itemsPerPage || 10,
        sortBy: sortBy,
        sortDesc: sortDesc
      };
      
      this.sortBy = this.pagination.sortBy[0] || "id";
      this.sortDesc = this.pagination.sortDesc[0] !== false;
      
      localStorage.setItem('requestPaginationSettings', JSON.stringify({
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc
      }));
      
      this.$nextTick(() => {
        this.fetchCommentsForVisibleItems();
      });
    },
    
    deleteItem(item) {
      this.selectedRequest = { ...item };
      this.$refs.deleteItem.show();
    },
    saveStartDate(date) {
      this.startDate = date;
      this.formattedStartDate = date;
      this.$refs.startMenu.save(date);  // Explicitly close the start menu
    },
    saveEndDate(date) {
      this.endDate = date;
      this.formattedEndDate = date;
      this.$refs.endMenu.save(date);  // Explicitly close the end menu
    },
    closeDateRangePicker() {
      this.showDateRangePicker = false;
    },
    async downloadReport() {
      try {
        if (!this.startDate || !this.endDate) {
          this.handleError("Selectează un interval de date valid.");
          return;
        }
  
        const params = {
          start: this.startDate,
          end: this.endDate
        };
  
        const response = await api.downloadReport(params);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report_${this.startDate}_${this.endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        this.showDateRangePicker = false;
      } catch (error) {
        console.error("Error downloading report:", error);
        this.handleError("Failed to download the report.");
      }
    },
    formatCreatedAt(dateString) {
      const result = helper.formatCreatedAt(dateString);
      return result;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "user") return item.user;
      if (header.value === "status") return item.status;
      if (header.value === "equipment") return item.equipment;
      if (header.value === "model") return item.model;
      if (header.value === "registration_number")
        return item.registration_number;
      if (header.value === "cause") return item.cause;
      if (
        header.value === "service_price" &&
        (this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector)
      ) {
        return item.service_price;
      }
      if (header.value === "date") return this.formatCreatedAt(item.date);
      if (header.value === "actions") return "";
      return "";
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      if (this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector) {
        this.visibleHeaders = this.headers
          .filter((header) => header.visible)
          .map(header => ({...header, sortable: header.value !== 'actions' && header.value !== 'comments'}));
      } else {
        this.visibleHeaders = this.headers
          .filter((header) => header.visible && header.notForUser)
          .map(header => ({...header, sortable: header.value !== 'actions' && header.value !== 'comments'}));
      }
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    formatDate(dateString) {
      if (!dateString) return '';
      return new Date(dateString).toLocaleString('ro-RO', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    getStatusColor(status) {
      const statusColors = {
        'Respins': 'error',
        'Îndeplinit': 'success',
        'În lucru': 'warning',
        'Imposibil': 'grey',
        'Emis de utilizator': 'primary',
        'Aprobat de Sef al Departamentului': 'info',
        'Aprobat de Rector': 'purple',
        'Aprobat de Contabilitate': 'deep-purple'
      };
      return statusColors[status] || 'grey';
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    isEngineerPC(){
      return this.$store.getters.getUser.role === "inginer-pc";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isContability() {
      return this.$store.getters.getUser.role === "contability";
    },
    isRector() {
      return this.$store.getters.getUser.role === "rector";
    },
    filteredData() {
      const result = helper.filteredData(this.requestsData, this.search);
      return result;
    },
    paginatedItems() {
      let sortedData = [...this.filteredData];
      
      if (this.pagination.sortBy && this.pagination.sortBy.length > 0) {
        const sortKey = this.pagination.sortBy[0];
        const sortDesc = this.pagination.sortDesc[0];
        
        sortedData.sort((a, b) => {
          let aValue, bValue;
          
          if (sortKey === 'date') {
            aValue = new Date(a.date || a.created_at).getTime();
            bValue = new Date(b.date || b.created_at).getTime();
          } else if (sortKey === 'id') {
            aValue = parseInt(a.id);
            bValue = parseInt(b.id);
          } else {
            aValue = a[sortKey];
            bValue = b[sortKey];
          }
          
          if (aValue < bValue) return sortDesc ? 1 : -1;
          if (aValue > bValue) return sortDesc ? -1 : 1;
          return 0;
        });
      }
      
      const startIndex = (this.pagination.page - 1) * this.pagination.itemsPerPage;
      const endIndex = startIndex + this.pagination.itemsPerPage;
      return sortedData.slice(startIndex, endIndex);
    }
  },
  mounted() {
    this.headers = require("./requests.json").map(header => ({
      ...header,
      sortable: header.value !== 'actions' && header.value !== 'comments'
    }));
    if (!(this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector)) {
      this.visibleHeaders = this.headers.filter(
        (header) => header.defaultVisible && header.notForUser 
      );
      this.headers = this.headers.filter(
        (header) => header.defaultVisible && header.notForUser
      );
    } else {
      this.visibleHeaders = this.headers.filter(
        (header) => header.defaultVisible
      );
    }
    this.headers.forEach((header) => {
      if (this.isAdmin || this.isEngineer || this.isDtiSef || this.isRector) {
        return (header.visible = header.defaultVisible && header.notForUser);
      } else {
        return (header.visible = header.defaultVisible && header.notForUser);
      }
    });
    
    this.$refs.deleteItem.$on("error", this.handleError);
    this.$refs.deleteItem.$on("success", this.handleSuccess);
    this.$refs.dialogForm.$on("error", this.handleError);
    this.$refs.dialogForm.$on("success", this.handleSuccess);
    
    this.$watch('$vuetify.breakpoint.name', () => {
      this.$nextTick(() => {
        this.fetchCommentsForVisibleItems();
      });
    });
    
    const savedPaginationSettings = localStorage.getItem('requestPaginationSettings');
    if (savedPaginationSettings) {
      try {
        const settings = JSON.parse(savedPaginationSettings);
        this.pagination = {
          page: settings.page || 1,
          itemsPerPage: settings.itemsPerPage || 10,
          sortBy: settings.sortBy || ["id"],
          sortDesc: settings.sortDesc || [true]
        };
        this.sortBy = this.pagination.sortBy[0] || "id";
        this.sortDesc = this.pagination.sortDesc[0] !== false;
      } catch (e) {
        console.error('Error parsing saved pagination settings:', e);
      }
    }
    
    this.$nextTick(() => {
      this.fetchRequestData();
    });
    
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('requestPaginationSettings', JSON.stringify({
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc
      }));
    });
  },
};
</script>

<style scoped>
.requests .v-data-table::v-deep tbody td {
  font-size: 18px;
}
.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
.red-row {
  background-color: #f29daa;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
.comments-wrapper {
  max-height: 150px;
  overflow-y: auto;
  padding: 4px;
  min-height: 60px; /* Increased minimum height to prevent layout shifts */
}

.compact-timeline {
  padding-top: 0;
}

.compact-item {
  padding-bottom: 8px !important;
}

.comment-header {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.comment-body {
  font-size: 0.85rem;
  color: #666;
  margin: 2px 0;
  white-space: normal;
  word-break: break-word;
}

.comment-date {
  font-size: 0.75rem;
  color: #999;
}

.no-comments {
  text-align: center;
  color: #999;
  padding: 10px;
  font-style: italic;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store';

Vue.use(VueRouter)

import HomePage from './../views/HomePage'
import HistoryPage from './../views/history/HistoryPage'
import RequestsPage from '../views/requests/RequestsPage'
import ApprovalPage from '../views/approval/ApprovalPage'
import EquipmentsPage from '../views/equipments/EquipmentsPage'
import UsersPage from '../views/users/UsersPage'
import LoginPage from '../views/auth/LoginPage'
import SignUp from '../views/auth/SignUp'
import NotFound from '../common/NotFound.vue'
import ProfileSettings from '../views/users/ProfileSettings.vue'
import HistoryDetails from '../views/history/HistoryDetails.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/approval',
    name: 'approval',
    component: ApprovalPage,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = isAuthenticatedMethod();
      const isUser = store.getters.getUser.role === "user";
      if (isAuthenticated && !isUser) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/equipments',
    name: 'eqhipments',
    component: EquipmentsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/history/:id',
    name: 'historyDetails',
    component: HistoryDetails,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = isAuthenticatedMethod();
      const isAdmin = store.getters.getUser.role === "admin";
      const isEngineer = store.getters.getUser.role === "inginer";
      const isDtiSef = store.getters.getUser.role === "dtisef";
      const isEngineerPC = store.getters.getUser.role === "inginer-pc";
      if (isAuthenticated && (isAdmin || isEngineer || isDtiSef || isEngineerPC)) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = isAuthenticatedMethod();
      if (isAuthenticated) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticatedMethod()) {
    next('/login');
  } else {
    next();
  }
});

function isAuthenticatedMethod() {
  const token = localStorage.getItem("token");
  return token !== null && token !== undefined;
}

export default router;
